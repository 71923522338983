import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import TourList from "./section-components/tour-list-v3";
import Subscribe from "./section-components/subscribe";
import Footer from "./global-components/footer";
import { tourListPage, allToursPage } from "../actions";

const TourListPageV3 = () => {
  const dispatch = useDispatch();

  // Accessing state using useSelector
  const tourlistpagedata = useSelector(
    (state) => state.pageData.tourlistpagedata
  );
  // const data = useSelector((state) => state.pageData.alltoursdata);

  // Fetch data and scroll to top on component mount
  useEffect(() => {
    dispatch(tourListPage());
    dispatch(allToursPage());
    window.scrollTo(0, 0);
  }, [dispatch]);

  return (
    <div>
      <Navbar />

      {tourlistpagedata && (
        <div>
          <PageHeader
            headertitle={tourlistpagedata.header.headertitle}
            backgroundImage={tourlistpagedata.header.backgroundImage}
          />
          <TourList data={tourlistpagedata.tourlist} />
        </div>
      )}

      <Subscribe />
      <Footer />
    </div>
  );
};

export default TourListPageV3;
