import React, { Component } from "react";
import { Link } from "react-router-dom";
import ImageLazyLoad from "../section-components/ImageLazyLoad";
import parse from "html-react-parser";

class HolidayCity extends Component {
  renderContent() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return this.props.data.map((singleData) => {
      const { imagejpeg, imagewebp, location, name, duration, price, link } =
        singleData;
      return (
        <div className="col-lg-3 col-sm-6">
          <div
            className="single-destinations-list style-two wow animated fadeInUp"
            data-wow-duration="0.4s"
            data-wow-delay="0.1s"
          >
            <Link to={link}>
              <div className="thumb">
                <ImageLazyLoad imagejpeg={imagejpeg} imagewebp={imagewebp} />
              </div>
              <div className="details text-left">
                <p className="location">
                  <img
                    src={
                      "https://cdn-icons-png.flaticon.com/256/5988/5988611.png"
                    }
                    alt="map"
                    style={{
                      width: "25px",
                    }}
                  />
                  {location}
                </p>

                <h4 className="title text-left">{name}</h4>
                <p className="content text-left">{duration}</p>
                <div className="tp-price-meta text-left">
                  <h2>{price}</h2>
                </div>
              </div>
            </Link>
          </div>
        </div>
      );
    });
  }
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const data = this.props.data[0];
    return (
      <div className="holiday-plan-area tp-holiday-plan-area mg-top-96">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-9">
              <div className="section-title text-center">
                <h2
                  className="title wow animated fadeInUp"
                  data-wow-duration="0.6s"
                  data-wow-delay="0.1s"
                >
                  Explore More with DBC
                </h2>
                <p
                  className="wow animated fadeInUp text-justify"
                  data-wow-duration="0.6s"
                  data-wow-delay="0.2s"
                >
                  {data.subheading}
                </p>
                <Link className="btn btn-yellow" to="tours/bespoke-tours">
                  View All <i className="fa fa-paper-plane" />
                </Link>
              </div>
            </div>
          </div>
          <div className="row">{this.renderContent()}</div>
        </div>
      </div>
    );
  }
}

export default HolidayCity;
