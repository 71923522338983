import React, { Component } from "react";
import { Link } from "react-router-dom";
import ImageLazyLoad from "../section-components/ImageLazyLoad";
import parse from "html-react-parser";
import axios from "axios";

class Ads extends Component {
  render() {
    // Define domain for local and deployed
    const api_domain = "https://www.delhibycycle.com";
    // const local_domain = "http://localhost:80";
    const klmClicks = async () => {
      try {
        const response = axios.post(`${api_domain}/klmBanner`);

        if (response.status == 200) {
          console.log("Person count updated successfully...");
        }
      } catch (error) {
        console.log(error);
      }
    };
    const data = this.props.data[0];
    const imagejpeg = data?.imagejpeg;
    const imagewebp = data?.imagewebp;

    return (
      <div
        className="ads-area pd-top-90 viaje-go-top"
        style={{ paddingBottom: "0" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div
              className="col-xl-12 col-lg-10 "
              style={{ margin: "-65px", marginBottom: "-35px" }}
              onClick={klmClicks}
            >
              <a
                className="ads-thumb "
                href="https://www.klm.co.in/"
                target="_blank"
              >
                <img
                  src={imagejpeg}
                  imagewebp={imagewebp}
                  style={{ width: "200vw", textAlign: "center" }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Ads;
