import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { homescreenPrompt } from "../../actions";
import Notification from "../section-components/Notification";
import TransitionsModal from "../section-components/Login";

const Navbar = () => {
  const dispatch = useDispatch();

  // Access state from Redux store
  const displayNotification = useSelector(
    (state) => state.modal.displayNotification
  );
  const notification = useSelector((state) => state.modal.notification);
  const deferredPrompt = useSelector((state) => state.auth.deferredPrompt);
  const homepagedata = useSelector((state) => state.pageData.homepagedata);

  const token = localStorage.getItem("token");
  const logo = homepagedata?.dbcLogo;

  useEffect(() => {
    const showAddToHomeScreen = () => {
      const timeoutId = setTimeout(() => {
        window.addEventListener("beforeinstallprompt", (event) => {
          event.prompt();
          dispatch(homescreenPrompt(event));
        });
      }, 3000);

      return () => clearTimeout(timeoutId); // Cleanup timeout on unmount
    };

    showAddToHomeScreen();
  }, [dispatch]);

  const renderUserProfileLink = () => {
    if (token) {
      return (
        <li>
          <Link to="/user-profile">User Profile</Link>
        </li>
      );
    }
    return null;
  };

  const publicUrl = process.env.PUBLIC_URL + "/";
  const imgattr = "logo";

  const navbarData = homepagedata?.navbarData || [];

  return (
    <nav className="navbar navbar-area navbar-expand-lg nav-style-01 viaje-go-top">
      <div className="container nav-container">
        {displayNotification && <Notification notification={notification} />}
        <div className="responsive-mobile-menu">
          <div className="mobile-logo">
            <Link to="/">
              <picture>
                <source srcSet={logo?.imagewebp} />
                <img src={logo?.imagejpeg} alt={imgattr} />
              </picture>
            </Link>
          </div>
          <button
            className="navbar-toggler float-right"
            type="button"
            data-toggle="collapse"
            data-target="#tp_main_menu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggle-icon">
              <span className="line" />
              <span className="line" />
              <span className="line" />
            </span>
          </button>
          <div className="nav-right-content">
            <ul className="pl-0">
              <li className="search">
                <i className="ti-search" />
              </li>
              <li className="notification">
                <a className="signUp-btn" href="#">
                  <i className="fa fa-user-o" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="collapse navbar-collapse" id="tp_main_menu">
          <div className="logo-wrapper desktop-logo">
            <Link
              to="/"
              className="main-logo"
              onClick={() => {
                if (deferredPrompt) {
                  deferredPrompt.prompt();
                  deferredPrompt.userChoice.then((data) => {
                    console.log(data);
                  });
                }
              }}
            >
              <picture>
                <source srcSet="https://dbcpictures.s3.ap-south-1.amazonaws.com/logo+and+graphics/transparent+logo+DBC.png" />
                <img
                  src="https://dbcpictures.s3.ap-south-1.amazonaws.com/logo+and+graphics/transparent+logo+DBC.png"
                  alt={imgattr}
                />
              </picture>
            </Link>
            <Link
              to="/"
              className="sticky-logo"
              onClick={() => {
                if (deferredPrompt) {
                  deferredPrompt.prompt();
                  deferredPrompt.userChoice.then((data) => {
                    console.log(data);
                  });
                }
              }}
            >
              <picture>
                <source
                  srcSet="https://dbcpictures.s3.ap-south-1.amazonaws.com/logo+and+graphics/transparent+logo+DBC.webp"
                  type="image/webp"
                />
                <source
                  srcSet="https://dbcpictures.s3.ap-south-1.amazonaws.com/logo+and+graphics/transparent+logo+DBC.png"
                  type="image/png"
                />
                <img
                  src="https://dbcpictures.s3.ap-south-1.amazonaws.com/logo+and+graphics/transparent+logo+DBC.png"
                  alt={imgattr}
                />
              </picture>
            </Link>
          </div>
          <ul className="navbar-nav">
            {navbarData.map((item, index) => (
              <li key={item.linkName} className="menu-item-has-children">
                <Link to={item.url}>{item.linkName}</Link>
                {item.sublinks?.length > 0 && (
                  <ul className="sub-menu">
                    {item.sublinks.map((sublink, subIndex) => (
                      <li>
                        <Link to={sublink.url}>{sublink.linkName}</Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
            {renderUserProfileLink()}
          </ul>
        </div>
        <div className="nav-right-content">
          <ul>
            <li className="notification">
              <a className="signUp-btn" href="#">
                <i className="fa fa-user-o" /> Login
              </a>
            </li>
          </ul>
        </div>
      </div>
      <TransitionsModal />
    </nav>
  );
};

export default Navbar;
