import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import Contact from "./section-components/contact";
import Subscribe from "./section-components/subscribe";
import Footer from "./global-components/footer";

const ContactPage = () => {
  const data = useSelector((state) => state.pageData.homepagedata);
  const pageData = data?.otherPages?.contactPage;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Runs only once when the component mounts

  return (
    <div>
      <Navbar />
      <PageHeader
        headertitle="Contact Us"
        backgroundImage={pageData?.headerImage}
      />
      <Contact
        imagewebp={pageData?.imagewebp}
        imagejpeg={pageData?.imagejpeg}
        address={pageData?.address}
        embedMap={pageData?.mapAddress}
      />
      <Subscribe />
      <Footer />
    </div>
  );
};

export default ContactPage;
