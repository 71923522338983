import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Navbar from "./global-components/navbar";
import Banner from "./section-components/banner";
import Ads from "./section-components/ads";
import HomeData from "./section-components/homeData";
import Offer from "./section-components/offer";
import HolidayPlan from "./section-components/holiday-plan";
import HolidayCity from "./section-components/tour-city";
import Subscribe from "./section-components/subscribe";
import Footer from "./global-components/footer";
import Client from "./section-components/client";
import BlogSection from "./blog-components/blog-section-v2";
import { homePage } from "../actions";

const bannerData = [
  {
    duration: "4 Hours",
    durationExtraInfo: "Morning Tour",
    facts1: "We hosted more than",
    facts2: "30,000+",
    facts3: "people",
    facts3extra: "on this tour",
    feature1: "Explore the organized chaos",
    feature2: "Old Delhi",
    feature3: "Breakfast",
    feature3extra: "Included",
    firstname: "The Shahjahan",
    lastname: "Tour",
    location: "Old Delhi Cycle Tour",
    price: "INR 2000",
    priceField: "price",
    imagejpeg:
      "https://dbcpictures.s3.ap-south-1.amazonaws.com/landing+page/shahjahan_tour_landing_page_banner_delhi_by_cycle.jpg",
    imagewebp:
      "https://dbcpictures.s3.ap-south-1.amazonaws.com/landing+page/shahjahan_tour_landing_page_banner_delhi_by_cycle.webp",
    video: "https://www.youtube.com/watch?v=7uOZSi1iUio",
  },
  {
    duration: "4 Hours",
    durationExtraInfo: "Morning Tour",
    facts1: "We hosted more than",
    facts2: "30,000+",
    facts3: "people",
    facts3extra: "on this tour",
    feature1: "Explore the organized chaos",
    feature2: "Old Delhi",
    feature3: "Breakfast",
    feature3extra: "Included",
    firstname: "The Shahjahan",
    lastname: "Tour",
    location: "Old Delhi Cycle Tour",
    price: "INR 2000",
    priceField: "price",
    imagejpeg:
      "https://dbcpictures.s3.ap-south-1.amazonaws.com/landing+page/shahjahan_tour_landing_page_banner_delhi_by_cycle.jpg",
    imagewebp:
      "https://dbcpictures.s3.ap-south-1.amazonaws.com/landing+page/shahjahan_tour_landing_page_banner_delhi_by_cycle.webp",
    video: "https://www.youtube.com/watch?v=7uOZSi1iUio",
  },
  {
    duration: "4 Hours",
    durationExtraInfo: "Morning Tour",
    facts1: "We hosted more than",
    facts2: "30,000+",
    facts3: "people",
    facts3extra: "on this tour",
    feature1: "Explore the organized chaos",
    feature2: "Old Delhi",
    feature3: "Breakfast",
    feature3extra: "Included",
    firstname: "The Shahjahan",
    lastname: "Tour",
    location: "Old Delhi Cycle Tour",
    price: "INR 2000",
    priceField: "price",
    imagejpeg:
      "https://dbcpictures.s3.ap-south-1.amazonaws.com/landing+page/shahjahan_tour_landing_page_banner_delhi_by_cycle.jpg",
    imagewebp:
      "https://dbcpictures.s3.ap-south-1.amazonaws.com/landing+page/shahjahan_tour_landing_page_banner_delhi_by_cycle.webp",
    video: "https://www.youtube.com/watch?v=7uOZSi1iUio",
  },
];

const Home_V1 = ({ history }) => {
  const url = "https://www.delhibycycle.com";

  const dispatch = useDispatch();
  const [bannerData, setBannerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const homepagedata = useSelector((state) => state.pageData.homepagedata);

  useEffect(() => {
    setBannerData(homepagedata);
  }, [homepagedata]);
  // console.log(bannerData);
  // const renderBanner = async () => {
  //   return await (<Banner data={bannerData} />)
  // }
  const BannerData =
    homepagedata === null ? HomeData.bannerData : homepagedata.banner;
  return (
    <div>
      <Navbar location={history.location.pathname} data={homepagedata} />

      <div>
        <Banner data={BannerData} />
        {homepagedata ? (
          <>
            <Offer data={homepagedata.offerData} offer={homepagedata.offer} />
            <HolidayPlan data={homepagedata.holidayData} />
            <HolidayCity data={homepagedata.holidayData2} />
            <BlogSection data={homepagedata.blogData} />
            <Client data={homepagedata.reviews} />
            <Ads data={homepagedata.klm} />
          </>
        ) : (
          <>
            {/* <Banner data={HomeData.bannerData} />/ */}
            <Offer data={HomeData.offerData} />
            <HolidayPlan data={HomeData.holidayData} />
            <HolidayCity data={HomeData.holidayData} />
            <BlogSection data={HomeData.blogData} />
            <Client data={HomeData.reviewsData} />
            <Ads data={HomeData.klmData} />
          </>
        )}
      </div>
      <Subscribe />
      <Footer />
    </div>
  );
};

export default Home_V1;
